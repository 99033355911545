import IIysData from "@/models/Entities/payments/IIysData";
import { getIysDataOfCustomer } from "../../../../services";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { DataTableStatusTemplate } from "../../../index";

interface IysDialogProps {
  visible: boolean;
  customer: any;
  onHide: () => void;
}

const IysDialog: React.FC<IysDialogProps> = ({
  visible,
  customer,
  //isLoading,
  onHide,
}) => {
  const [iysData, setIysData] = useState<IIysData[]>([]);

  useEffect(() => {
    console.log("IysDialog");
    if (customer.id) {
      getIysDataOfCustomer(customer?.id).then((response) => {
        if (response) {
          console.log("response:", response);
          setIysData(response);
        }
      });
    }
  }, [customer]);

  const hideDialog = () => {
    onHide();
  };

  return (
    <Dialog
      visible={visible}
      style={{ width: "70%" }}
      modal
      className="p-fluid"
      onHide={hideDialog}
    >
      <div className="col-12 xl:col-12">
        <div className="card">
          <h5>İleti İzinleri</h5>
          <DataTable value={iysData} rows={5} responsiveLayout="scroll">
            <Column field="id" header="ID" style={{ width: "5%" }} />
            <Column
              field="recipient"
              header="Alıcı Mail"
              sortable
              style={{ width: "25%" }}
            />
            <Column
              field="createDate"
              header="Tarih"
              style={{ width: "20%" }}
              body={(rowData) =>
                rowData.createDate && (
                  <>
                    {moment(rowData.createDate).format("DD/MM/YYYY HH:mm:ss")}
                  </>
                )
              }
            />
            <Column field="status" header="Durum" style={{ width: "20%" }} />
            <Column
              field="updateDate"
              header="Güncelleme Tarihi"
              style={{ width: "30%" }}
              body={(rowData) =>
                rowData.updateDate && (
                  <>
                    {moment(rowData.updateDate).format("DD/MM/YYYY HH:mm:ss")}
                  </>
                )
              }
            />
            <Column
              field="synchStatus"
              header="Iys Synch Status"
              style={{ width: "15%" }}
              body={(rowData) =>
                rowData.synchStatus && (
                  <DataTableStatusTemplate status={rowData?.synchStatus} />
                )
              }
            />
          </DataTable>
        </div>
      </div>
    </Dialog>
  );
};

export default IysDialog;
