import { axiosInstance } from "../../api/axios.instance";
import { fetchCustomers } from "../../services/index";
import { useQuery } from "react-query";

interface UseGetCustomersParams {
  page?: number;
  size?: number;
  name?: string;
  email?: string;
  city?: string;
  status?: string;
  gender?: string;
  surname?: string;
}

export const useGetCustomersData = (params: UseGetCustomersParams = {}) => {
  const token =
    axiosInstance.defaults.headers.common["Authorization"] !== undefined;

  // React Query ile fetchCustomers çağrısı
  const { data: customersWithPage, isLoading: getCustomersIsLoading } =
    useQuery(
      ["customers", params], // Query key ile params'ı bağımlılık olarak geçiyoruz
      () => fetchCustomers(params), // fetchCustomers metoduna params'ı geçiriyoruz
      {
        enabled: token, // Token varsa sorgu çalışır
        keepPreviousData: true, // Sayfalar arası geçişte önceki veriyi tutar
      }
    );

  return { customersWithPage, getCustomersIsLoading };
};
