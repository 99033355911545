import { STATUS_TYPES } from "../../../../constants/index";
import React from "react";

interface DataTableStatusTemplateProps {
  status: string;
}

const DataTableStatusTemplate: React.FC<DataTableStatusTemplateProps> = ({
  status,
}) => {
  return (
    <span
      className={`badge ${
        status === STATUS_TYPES.ACTIVE || status === "READY_FOR_PUBLISH"
          ? "status-ACTIVE"
          : status === STATUS_TYPES.INACTIVE || status === "EXPIRED"
          ? "status-INACTIVE"
          : status === "PUBLISHED"
          ? "status-PUBLISHED"
          : status === "IN_PROGRESS"
          ? "status-IN_PROGRESS"
          : status === "RAW_CONTENT"
          ? "status-RAW_CONTENT"
          : status === "DELETED"
          ? "status-DELETED"
          : status === "ENDED"
          ? "status-ENDED"
          : status === "WAITING_PAYMENT"
          ? "status-WAITING_PAYMENT"
          : status === "IDLE"
          ? "status-WAITING_FOR_MATERIALS"
          : status === "WAITING"
          ? "status-WAITING_FOR_MATERIALS"
          : status === "SYNCHED"
          ? "status-ACTIVE"
          : status === "SENT"
          ? "status-ACTIVE"
          : status === "WAITING_FOR_MATERIALS"
          ? "status-WAITING_FOR_MATERIALS"
          : "status-WAITING_FOR_MATERIALS"
      }`}
    >
      {status.replaceAll("_", " ")}
    </span>
  );
};

export default DataTableStatusTemplate;
