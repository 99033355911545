import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import {
  DataTableActionTemplate,
  DataTableStatusTemplate,
  CustomerDetailsDialog,
} from "../../../index";
import { ICustomer } from "../../../../models/index";
import {
  customerFiltersNew,
  initialCustomer,
} from "../../../../constants/index";
import { useGetCustomersData } from "../../../../hooks/index";
import {
  DataTable,
  DataTableFilterMeta,
  DataTableFilterMetaData,
} from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { STATUS_TYPES } from "./../../../../constants/statues";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import * as XLSX from "xlsx";
import { Button } from "primereact/button";
import { FilterMatchMode } from "primereact/api";

const CustomerDataTable = () => {
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(
    null
  );
  const [customer, setCustomer] = useState<ICustomer>(initialCustomer);
  //const [filters, setFilters] = useState(customerFilters);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [customerDetailsDialog, setCustomerDetailsDialog] = useState(false);
  //const { customers, getCustomersIsLoading } = useGetCustomersData();
  const toast = useRef<Toast>(null);

  const [pagination, setPagination] = useState({ page: 0, size: 30 });

  const [filters, setFilters] = useState<DataTableFilterMeta>({
    name: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    } as DataTableFilterMetaData,
    email: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    } as DataTableFilterMetaData,
    city: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    } as DataTableFilterMetaData,
    status: {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
    } as DataTableFilterMetaData,
    surname: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    } as DataTableFilterMetaData,
  });
  const statusOptions = [
    STATUS_TYPES.ACTIVE,
    STATUS_TYPES.INACTIVE,
    STATUS_TYPES.DELETED,
    STATUS_TYPES.PENDING,
  ];

  const formatFilters = (
    filters: DataTableFilterMeta
  ): Record<string, string> => {
    const formattedFilters: Record<string, string> = {};

    Object.entries(filters).forEach(([key, value]) => {
      // Eğer value null veya boş string değilse ekle
      if (
        value &&
        "value" in value &&
        value.value !== null &&
        value.value !== ""
      ) {
        formattedFilters[key] = value.value; // Değeri ekle
      }
    });

    return formattedFilters;
  };

  const formattedFilters = formatFilters(filters);

  // Hook ile veriyi al
  const { customersWithPage, getCustomersIsLoading } = useGetCustomersData({
    page: pagination.page,
    size: pagination.size,
    ...formattedFilters,
  });

  //const { customerd, getCustomerdIsLoading, refetchGetCustomerd } =
  //useGetCustomerDetails(customer.id);
  //const [isAddItem, setIsAddItem] = useState(false);
  //const [deleteCustomerDialog, setDeleteCustomerDialog] = useState(false);
  //const [customerDialog, setCustomerDialog] = useState(false);
  //const { mutate: addCustomerMutate, addCustomerIsLoading } = useAddCustomerData(toast);
  //const { mutate: updateCustomerMutate } = useUpdateCustomerData(toast);
  //const { mutate: deleteCustomerMutate } = useDeleteCustomerData(toast);
  //const typeOptions = ["TEST"];

  /*
  
  const openDeleteCustomerDialog = (customer?: ICustomer) => {
    customer && setCustomer(customer);
    setDeleteCustomerDialog(true);
    };
    const openAddCustomerDialog = () => {
      setCustomer(initialCustomer);
      setIsAddItem(true);
      setCustomerDialog(true);
      };
      const openEditCustomerDialog = (rowData: ICustomer) => {
        //setCustomer({ ...rowData });
        //setIsAddItem(false);
        //setCustomerDialog(true);
        };
        
        const hideCustomerDialog = () => {
          setCustomerDialog(false);
          };
          
          
          const saveCustomer = (newCustomer: ICustomer) => {
            isAddItem
            ? addCustomerMutate(newCustomer)
            : updateCustomerMutate(newCustomer);
            !isAddItem && hideCustomerDialog();
            };
            */

  // Sayfa değişikliklerini yakalayın
  const handlePageChange = (event: any) => {
    setPagination({ page: event.page, size: event.rows });
  };

  // Filtre değişikliklerini işleme
  const handleFilterChange = (newFilters: DataTableFilterMeta) => {
    const updatedFilters = Object.entries(
      newFilters
    ).reduce<DataTableFilterMeta>((acc, [key, value]) => {
      // Tür kontrolü: DataTableFilterMetaData mı?
      if (value && "value" in value && "matchMode" in value) {
        acc[key] = {
          value: value.value || null,
          matchMode: value.matchMode || FilterMatchMode.CONTAINS,
        };
      }
      return acc;
    }, {});
    setFilters(updatedFilters);
  };

  const openCustomerDetailsDialog = async (rowData: ICustomer) => {
    await setCustomer({ ...rowData });
    //await refetchGetCustomerd();
    setCustomerDetailsDialog(true);
  };

  const hideCustomerDetailsDialog = () => {
    setCustomerDetailsDialog(false);
  };

  /*
  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  */

  const renderHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h5 className="mx-0 my-1">
          {"Manage Customers (Toplam:"} {customersWithPage?.totalElements + ")"}
        </h5>
        <div>
          <Button
            className="p-button-secondary p-button-outlined mr-2"
            label="Excel"
            icon="pi pi-file-excel"
            onClick={() => exportToExcel(customersWithPage?.customerPs)}
          />
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              //onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </div>
    );
  };

  const statusItemTemplate = (option: any) => {
    return <span className={`badge status-${option}`}>{option}</span>;
  };

  const statusRowFilterTemplate = (options: any) => {
    return (
      <Dropdown
        value={options.value}
        options={statusOptions}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
        style={{ minWidth: "13rem" }}
      />
    );
  };

  const exportToExcel = (data: any[] | undefined) => {
    if (!data) return;
    // 1. Başlıkları düzenleyin
    const headers = {
      id: "ID",
      creationDate: "Kayıt Tarihi",
      name: "Ad Soyad",
      email: "E-posta",
      gender: "Cinsiyet",
      city: "Şehir",
      status: "Durum",
    };

    // 2. Veriyi başlıklarla eşleştirin ve tarih formatını düzenleyin
    const formattedData = data.map((item) => ({
      [headers.id]: item.id,
      [headers.creationDate]: new Date(item.creationDate).toLocaleString(),
      [headers.name]:
        item.name + (item.surname !== null ? " " + item.surname : ""),
      [headers.email]: item.email,
      [headers.gender]: item.gender,
      [headers.city]: item.city,
      [headers.status]: item.status,
    }));

    // 3. Veriyi worksheet'e dönüştürün
    const worksheet = XLSX.utils.json_to_sheet(formattedData); // Düzenlenmiş veriyi kullanın

    // 4. Stil veya genişlik eklemek için worksheet ayarlarını düzenleyin
    const cols = [
      { wpx: 50 },
      { wpx: 150 }, // creationdate sütunu genişliği
      { wpx: 150 }, // Ad sütunu genişliği
      { wpx: 200 }, // E-posta sütunu genişliği
      { wpx: 100 }, // Abonelik Tarihi sütunu genişliği
      { wpx: 100 }, // Abonelik Tarihi sütunu genişliği
      { wpx: 100 }, // Abonelik Tarihi sütunu genişliği
    ];
    worksheet["!cols"] = cols;

    // 5. Yeni workbook oluşturun
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Müşteriler");

    // 6. Excel dosyasını oluşturun ve indirin
    const now = new Date();

    // Türkiye saat dilimine göre formatlanmış tarih ve saat
    const formattedDate = new Intl.DateTimeFormat("tr-TR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "Europe/Istanbul",
    }).format(now);

    // Dosya adı için geçerli format
    const sanitizedDate = formattedDate.replace(/[\/:\s]/g, "-"); // Geçersiz karakterleri '-' ile değiştir
    XLSX.writeFile(workbook, `Müşteriler-${sanitizedDate}.xlsx`);
  };

  const header = renderHeader();
  return (
    <>
      <Toast ref={toast} />
      {/* <DataTableToolbar
        openDeleteDialog={openDeleteCustomerDialog}
        openAddDialog={openAddCustomerDialog}
        disableDeleteButton={!selectedCustomer}
      /> */}
      <DataTable
        value={customersWithPage?.customerPs}
        paginator
        rows={pagination.size}
        dataKey="id"
        lazy
        selection={selectedCustomer}
        onSelectionChange={(e: any) => setSelectedCustomer(e.value)}
        onPage={handlePageChange}
        filters={filters}
        onFilter={(e) => handleFilterChange(e.filters)}
        filterDisplay="row"
        loading={getCustomersIsLoading}
        responsiveLayout="scroll"
        //globalFilterFields={["name", "city.name", "status"]}
        header={header}
        emptyMessage="No customers found."
        totalRecords={customersWithPage?.totalElements || 0}
        first={pagination.page * pagination.size}
      >
        {/* <Column
          selectionMode="single"
          headerStyle={{ width: "3rem" }}
          exportable={false}
        /> */}
        <Column
          field="id"
          header={"ID"}
          style={{ maxWidth: "18rem", minWidth: "14.5rem" }}
        />
        <Column
          field="email"
          header={"Email"}
          filter
          filterPlaceholder="Search by email"
          sortable
          style={{ maxWidth: "18rem", minWidth: "14.5rem" }}
        />
        <Column
          field="name"
          header={"Name"}
          filter
          filterPlaceholder="Search by name"
          sortable
          style={{ maxWidth: "14rem", minWidth: "12rem" }}
        />
        <Column
          field="surname"
          header={"Surname"}
          filter
          filterPlaceholder="Search by surname"
          sortable
          style={{ maxWidth: "14rem", minWidth: "12rem" }}
        />
        <Column
          field="status"
          header={"Status"}
          filter
          filterElement={statusRowFilterTemplate}
          showFilterMenu={false}
          sortable
          style={{ maxWidth: "12rem", minWidth: "8rem" }}
          body={(rowData: ICustomer) => (
            <DataTableStatusTemplate status={rowData.status} />
          )}
        />
        <Column
          field="type"
          header={"Type"}
          sortable
          style={{ maxWidth: "14rem", minWidth: "10rem" }}
        />

        <Column
          field="creationDate"
          header={"Creation Date"}
          sortable
          style={{ maxWidth: "16rem", minWidth: "12.5rem" }}
          body={(rowData) => (
            <>{moment(rowData.creationDate).format("DD/MM/YYYY")}</>
          )}
        />

        <Column
          body={(rowData: ICustomer) => (
            <DataTableActionTemplate
              //openEditDialog={() => openEditCustomerDialog(rowData)}
              openDetails={() => openCustomerDetailsDialog(rowData)}
            />
          )}
          exportable={false}
          style={{ maxWidth: "12rem", minWidth: "12.5rem" }}
        />
      </DataTable>
      {/*
      <DataTableDeleteDialog
        visible={deleteCustomerDialog}
        data={selectedCustomer ?? customer}
        onHide={hideDeleteCustomerDialog}
        onDelete={deleteCustomer}
      />
      
      <CustomerDialog
        visible={customerDialog}
        customer={customer}
        isLoading={addCustomerIsLoading}
        onHide={hideCustomerDialog}
        addCustomer={saveCustomer}
      />
      */}

      <CustomerDetailsDialog
        visible={customerDetailsDialog}
        customer={customer}
        //isLoading={getCustomerdIsLoading}
        onHide={hideCustomerDetailsDialog}
      />
    </>
  );
};

export default CustomerDataTable;
