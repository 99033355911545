import { log } from "console";
import { axiosInstance } from "../../api/axios.instance";

type StateAnalyticsData =
  | [string, [string, number], [string, number], [string, number]]
  | [string];

export const getCustomerAnalyticsByState = async (): Promise<
  StateAnalyticsData[]
> => {
  const token =
    axiosInstance.defaults.headers.common["Authorization"] !== undefined;
  //console.log("tokne:", token);

  if (!token) {
    return [];
  }

  const response = await axiosInstance.get(
    "/crm-core/analytics/customerByState"
  );
  return response.data;
};

export const getSubscriptionAnalyticsByState = async (): Promise<
  StateAnalyticsData[]
> => {
  const token =
    axiosInstance.defaults.headers.common["Authorization"] !== undefined;
  //console.log("tokne:", token);

  if (!token) {
    return [];
  }

  const response = await axiosInstance.get(
    "/crm-core/analytics/subscriptionByState"
  );
  return response.data;
};

export const getFailedPayments = async () => {
  const token =
    axiosInstance.defaults.headers.common["Authorization"] !== undefined;
  //console.log("tokne:", token);

  if (!token) {
    return [];
  }

  const response = await axiosInstance.get("/crm-core/payment/failedPayments");
  return response.data;
};

export const getIdleInvoices = async () => {
  const token =
    axiosInstance.defaults.headers.common["Authorization"] !== undefined;
  //console.log("tokne:", token);

  if (!token) {
    return [];
  }

  const response = await axiosInstance.get("/crm-core/payment/idleInvoices");
  return response.data;
};

export const getFailedInvoices = async () => {
  const token =
    axiosInstance.defaults.headers.common["Authorization"] !== undefined;
  //console.log("tokne:", token);

  if (!token) {
    return [];
  }

  const response = await axiosInstance.get("/crm-core/payment/failedInvoices");
  return response.data;
};

export const getInvoicesCount = async () => {
  const token =
    axiosInstance.defaults.headers.common["Authorization"] !== undefined;
  //console.log("tokne:", token);

  if (!token) {
    return [];
  }

  const response = await axiosInstance.get(
    "/crm-core/payment/failedInvoiceCount"
  );
  return response.data;
};

export const getWeeklyIncome = async () => {
  const token =
    axiosInstance.defaults.headers.common["Authorization"] !== undefined;
  //console.log("tokne:", token);

  if (!token) {
    return null;
  }

  const response = await axiosInstance.get(
    "/crm-core/analytics/incomeLastWeek"
  );
  return response.data;
};

export const getTotalIncome = async () => {
  const token =
    axiosInstance.defaults.headers.common["Authorization"] !== undefined;
  //console.log("tokne:", token);

  if (!token) {
    return 0;
  }

  const response = await axiosInstance.get("/crm-core/analytics/totalIncome");
  return response.data;
};

export const getCustomerCountByStatus = async (status: string) => {
  const token =
    axiosInstance.defaults.headers.common["Authorization"] !== undefined;
  //console.log("tokne:", token);

  if (!token) {
    return "";
  }

  const response = await axiosInstance.get(
    "/crm-core/customer/countbystatus/" + status
  );
  return response.data;
};
